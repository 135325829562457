.view-base-root {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    // background-image: radial-gradient(circle, rgb(40, 10, 59) 10%, rgb(87, 35, 134) 100%);
    background-image: url("../../assets/icons/bg.jpg");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    // background: rgb(63,94,251);
    // background: linear-gradient(180deg, rgba(63,94,251,1) 0%, rgba(177,157,255,1) 100%);

    .cubes-top {
        opacity: 0.6;
        position: absolute;
        top: -120px;

        p {
            font-family: Courier;
            font-style: normal;
            font-weight: normal;
            font-size: 6px;
            color: #ffffff;
            white-space: pre-wrap;
            user-select: none;
        }
    }

    .cubes-bottom {
        opacity: 0.6;
        position: absolute;
        bottom: -256px;
        right: -140px;

        p {
            font-family: Courier;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            color: #ffffff;
            white-space: pre-wrap;
            user-select: none;
        }
    }
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 5px;
}

.mobile-para {
    color: whitesmoke;
    font-size: 18px;
}
