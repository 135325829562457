.time-menu-root {
    .time-menu-btn {
        padding: 9px 20px;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 10px;
        cursor: pointer;
        margin-right: 20px;

        p {
            font-family: Proxima;
            font-weight: 600;
            font-size: 16px;
            color: #ffffff;
        }
    }
}

.time-menu-popper {
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(100px);
    border-radius: 10px;
    overflow: hidden;
    z-index: 10;

    .tooltip {
        display: flex;
        flex-direction: column;

        .tooltip-item {
            text-decoration: none;
            padding: 13px 20px;
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            p {
                font-family: Proxima;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                text-align: center;
            }
        }

        .add-tokens {
            display: flex;
            flex-direction: column;

            .add-tokens-title {
                font-family: Proxima;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #ffffff;
                text-align: center;

                margin: 5px 0px;
            }
        }

        .divider {
            height: 1px;
            background: #ffffff;
            border: none;
        }
    }
}
