.connect-button {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 10px;
    padding: 9px 20px;
    cursor: pointer;

    display: flex;
    align-items: center;

    .connect-button-progress {
        margin-left: 10px;
        margin-top: 4px;
    }

    p {
        font-family: Proxima;
        font-weight: 600;
        font-size: 16px;
        color: #ffffff;
    }
}
