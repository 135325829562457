.leaderboard-view {
    color: #ffffff;
    width: 89%;
    max-width: 600px;
    margin: auto;

    .leaderboard-card {
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(217, 217, 217, 0.05);
            backdrop-filter: blur(20px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        border-radius: 20px;
        padding: 20px;
        width: 100%;

        .leaderboard-card-grid {
            margin: 0;
            width: 100%;
        }

        .leaderboard-card-header {
            display: flex;
            justify-content: space-between;

            .leaderboard-card-header-title {
                font-family: Proxima;
                font-weight: 700;
                font-size: 30px;
                color: #ffffff;
                margin-bottom: 4px;
            }
        }
        .leaderboard-card-subheader {
            font-family: Proxima;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
            margin-top: 10px;
        }

        .leaderboard-card-area {

            .leaderboard-token-data {
                justify-content: center;
                margin: auto;
                padding: 0 5px;
                margin-top: 30px;

                .data-row {
                    display: flex;
                    justify-content: space-between;
                    background: rgba(159, 141, 229, 0.1);
                    border-radius: 12px;
                    padding: 0.5rem 0.75rem;
                    align-items: center;

                    .data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        .data-row-name-title {
                            margin-left: 8px;
                        }
                    }
                    .data-row-value {
                        font-family: Proxima;
                        margin: 6px 0px;
                        font-size: 14px;
                        line-height: 29px;
                        color: #ffffff;
                    }
                    .data-row-link {
                        font-size: 12px;
                        color: #ffffff;
                        font-family: Proxima;
                        font-weight: 500;

                        .data-row-link-btn {
                            background: rgba(159, 141, 229, 0.1);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            backdrop-filter: blur(14.7566px);
                            border: 3px solid rgba(255, 255, 255, 0.05);
                            border-radius: 16px;
                            padding: 10px;
                            cursor: pointer;
                            text-align: center;

                            p {
                                font-size: 10px;
                                color: #ffffff;
                                font-family: Proxima;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }

    }
}
