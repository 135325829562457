.dapp-sidebar {
    height: 100vh;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    background: rgba(217, 217, 217, 0.05);
    backdrop-filter: blur(20px);

    .branding-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        margin: 20px 0 63px 0;

        .wallet-link {
            margin: 10px;

            p {
                color: #ffffff;
            }
        }
    }
    p {
        font-family: Proxima;
        font-weight: 500;
        color: #ffffff;
    }

    .dapp-menu-links {
        display: flex;
        align-items: center;

        .dapp-nav {
            display: flex;
            flex-flow: column;
            width: 100%;

            a {
                margin: 20px;
                padding: 18px 15px;
                align-items: center;
                text-decoration: none;

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 15px;
                    vertical-align: -4px;
                }
            }

            .button-dapp-menu {
                background: rgba(11, 0, 30, 0.05);
                border-radius: 12px;

                .dapp-menu-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-weight: 600;
                        font-size: 20px;
                        color: #ffffff;
                        font-family: Proxima;
                    }
                }
            }

            .button-dapp-menu:hover {
                background: rgba(11, 0, 30, 0.1);
            }

            .button-dapp-other-menu {
                .dapp-menu-item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    p {
                        font-weight: 500;
                        font-size: 15px;
                        color: #ffffff;
                    }
                }
            }

            .button-dapp-menu.active {
                background: rgba(11, 0, 30, 0.4);
                .dapp-menu-item {
                    p {
                        font-weight: bold;
                    }
                }
            }

            .bond-discounts {
                text-align: left;
                padding-left: 54px;

                a {
                    margin-left: 0;
                    margin-top: 20px;
                    margin-bottom: 20px;
                }

                p {
                    font-family: Proxima;
                    font-size: 14px;
                    line-height: 17px;
                    color: rgba(255, 255, 255, 0.6);
                }

                .bond {
                    display: block;
                    padding: unset;
                    margin-bottom: 0 0 1px 0;
                    text-decoration: none !important;
                    .bond-pair-roi {
                        float: right;
                        margin-left: 33px;
                    }
                }
            }
        }
    }

    .dapp-menu-doc-link {
        margin-top: auto;

        a {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                vertical-align: middle;
            }

            p {
                font-family: Proxima SemiBold;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
            }
        }
    }

    .social-row {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        padding: 1.3rem;
        a {
            &:hover {
                transform: scale(1.1);
            }
            img {
                height: 20px;
                width: 20px;
            }
        }
    }

    .wallet-row {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        padding: 1.3rem;
        a {
            display: flex;
            background: rgba(11, 0, 30, 0.05);
            padding: 15px;
            border-radius: 12px;
            text-decoration: none;
            &:hover {
                transform: scale(1.1);
            }
            img {
                height: 20px;
                width: 20px;
            }
            p {
                padding-left: 5px;
            }
        }
    }
}

.MuiDrawer-root {
    .MuiPaper-root {
        z-index: 7;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(36, 39, 42, 0.1);
            backdrop-filter: blur(40px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }
    }
}

.menu-divider {
    margin: 20px;
}

.MuiDivider-root {
    border: none;
    height: 1px;
    margin: 0;
    flex-shrink: 0;
    background-color: rgba(255, 255, 255, 0.12);
}
