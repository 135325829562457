.swap-view {
    width: 89%;
    max-width: 600px;
    margin: auto;

    .swap-card {
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(217, 217, 217, 0.05);
            backdrop-filter: blur(20px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        border-radius: 20px;
        padding: 20px;
        width: 100%;

        .swap-card-grid {
            margin: 0;
            width: 100%;
        }

        .swap-card-header {
            display: flex;
            justify-content: space-between;

            .swap-card-header-title {
                font-family: Proxima;
                font-weight: 700;
                font-size: 30px;
                color: #ffffff;
                margin-bottom: 4px;
            }

            .setting-icon:hover {
                cursor: pointer;
            }
        }

        .swap-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;

            .swap-card-apy,
            .swap-card-tvl,
            .swap-card-index {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .swap-card-metrics-title {
                    font-family: Proxima Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 2px;
                }

                .swap-card-metrics-value {
                    font-family: Proxima Bold;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    color: #ffffff;
                }
            }
        }

        .swap-card-area {
            .swap-card-wallet-notification {
                text-align: center;

                .swap-card-wallet-connect-btn {
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                    border-radius: 10px;
                    padding: 14px 58px;
                    cursor: pointer;
                    margin: auto;
                    max-width: 280px;
                    margin-top: 20px;

                    p {
                        font-family: Proxima;
                        font-weight: 600;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                .swap-card-wallet-desc-text {
                    font-family: Proxima;
                    font-weight: 300;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
            }

            .swap-card-action-area {
                width: 100%;
                margin-top: 25px;

                &.no-gutters {
                    margin-top: 2px;

                    .swap-card-action-row {
                        display: flex;
                        align-items: center;
                        margin-top: 0;
                    }

                }

                .swap-card-action-stage-btns-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .swap-card-action-stage-btn {
                        margin: 0px 30px;
                        padding: 6px;
                        cursor: pointer;
                        transition: all 0.4s ease;

                        p {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }

                    .swap-card-action-stage-btn.active {
                        p {
                            color: #ffffff;
                        }

                        border-bottom: 1px solid #ffffff;
                    }
                }

                .swap-card-action-row {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    &.relative {
                        position: relative;
                    }

                    .swap-card-action-input {
                        width: 100%;
                        height: 108px;
                        background: rgba(11, 0, 30, 0.2);
                        border-radius: 12px;
                        outline: none;
                        position: relative;

                        .MuiOutlinedInput-input {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            color: #ffffff;
                            padding: 14px 20px;
                            text-align: right;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: rgba(255, 255, 255, 0.2) !important;
                        }

                        .swap-card-action-input-btn {
                            cursor: pointer;
                            border: 1px solid rgba(255, 255, 255, 0.6);
                            border-radius: 8px;
                            padding: 6px 10px;
                            background-color: rgba(255, 255, 255, 0.2);

                            p {
                                font-family: Proxima;
                                font-weight: 600;
                                font-size: 14px;
                                color: #ffffff;
                            }
                        }
                    }

                    .swap-card-select-input {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        border-radius: 16px;
                        outline: none;
                        width: auto;
                        // height: 100%;
                        position: absolute;
                        right: 1.5rem;
                        margin: 1.5rem 1rem;
                        

                        .MuiSelect-select {
                            display: inline-flex;
                            align-items: center;
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.6);
                            padding: 10px 20px;
                        }
                        .MuiSelect-icon {
                            color: #ffffff;
                        }

                        .token-info {
                            display: flex;
                            flex-direction: column;
                            justify-items: center;
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;

                            .token-title {
                                font-size: 1.25rem;
                                font-weight: 600;
                                color: #ffffff;
                            }
    
                            .token-balance {
                                font-size: 0.65rem;
                                font-weight: 300;
                                color: #ffffff86;
                            }

                        }

                        .swap-card-action-input-btn {
                            cursor: pointer;

                            p {
                                font-family: Proxima;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }

                    .swap-card-tab-panel {
                        min-width: 215px;
                        margin-left: 10px;

                        .swap-card-tab-panel-btn {
                            // background: rgba(255, 255, 255, 0.2);
                            background: linear-gradient(180deg, rgba(159, 141, 229, 0.3) 0%, rgba(30, 49, 140, 0.3) 68.75%), rgba(217, 217, 217, 0.4);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            backdrop-filter: blur(14.7566px);
                            border: 0.737828px solid rgba(255, 255, 255, 0.6);
                            border-radius: 12px;
                            padding: 14px 0px;
                            cursor: pointer;
                            text-align: center;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                        .swap-card-tab-panel-text-left {
                            font-family: Proxima;
                            font-style: bold;
                            font-size: 16px;
                            color: #ffffff;
                        }
                    }
                    .swap-card-tab-panel-center {
                        width: 100%;
                        text-align: center;

                        .swap-img {
                            border: 1px solid #ffffff;
                            padding: 0.5rem;
                            border-radius: 50%;
                            background: rgba(255, 255, 255, 0.1);
                            box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.25);
                            backdrop-filter: blur(7.5px);
                        }

                        .swap-img:hover {
                            cursor: pointer;
                            background-color: rgba(255, 255, 255, 0.2);
                        }
                    }
                    .swap-card-tab-panel-text-right {
                        font-family: Proxima;
                        font-style: normal;
                        font-size: 14px;
                        color: #ffffff;
                        text-align: right;
                        width: 100%;
                    }
                }

                .swap-card-action-help-text {
                    margin-top: 10px;

                    p {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }

            .swap-user-data {
                justify-content: center;
                margin: auto;
                padding: 0 5px;
                margin-top: 30px;
                text-align: center;

                .data-row {
                    // display: flex;
                    // justify-content: space-between;
                    flex-direction: column;
                    background: rgba(159, 141, 229, 0.1);
                    border-radius: 12px;
                    padding: 1rem;

                    .data-row-title {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .data-row-name {
                            font-family: Proxima;
                            font-style: normal;
                            margin-top: 6px;
                            font-weight: 500;
                            font-size: 12px;
                            color: #ffffffa9;
                            line-height: 14px;
                            letter-spacing: 0.4em;
                        }
                        .tooltip-icon {
                            color: #ffffffa9;
                            margin-left: 8px;
                        }
                    }

                    .data-row-value {
                        font-family: Proxima;
                        margin: 6px 0px;
                        font-size: 21px;
                        line-height: 29px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.MuiMenuItem-root {
    display: inline-flex;
    align-items: center;
    font-family: Proxima !important;

    .token-info {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .token-title {
            font-size: 0.875rem;
            font-weight: 600;
            font-style: normal;
        }

        .token-balance {
            font-size: 8px;
            font-weight: 300;
            font-style: light;
            color: #777;
        }

    }
}

.mt-0 {
    margin-top: 0;
}
.mt-3 {
    margin-top: 2rem;
}
.mb-3 {
    margin-bottom: 2rem;
}

.text-light {
    color: #ffffff;
}
.text-secondary {
    color: #777;
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: 700;
}
.swap-modal-box {
    position: absolute;
    // box-shadow: 0px 0px 10px #777;
    top: 50%;
    left: 50%;
    max-width: 600px;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(40px);
    padding: 2rem;
    text-align: start;
    border: 1px solid #777;
    border-radius: 12px;
    transform: translate(-50%, -50%);

    .modal-text-area {
        background-color: #efebff;
        border: 1px solid #e7e1ff;
        border-radius: 16px;
        display: flex;
        align-items: center;
        padding: 0.75rem;
        margin-top: 0.75rem;

        .text-title {
            margin-left: 0.5rem;
            text-transform: uppercase;
        }
        .text-number {
            margin-left: auto;
            font-weight: bold;
        }
    }
    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .modal-title {
            font-family: Proxima;
            font-weight: 700;
            font-size: 24px;
            color: #ffffff;
        }
        .modal-close {
            color: #ffffff;
            cursor: pointer;
            font-weight: 700;
        }
    }

    .modal-normal-text {
        font-family: Proxima;
        font-size: 16px;
        color: #f5f5f5;
        margin-top: 20px;
        text-align: start;
        font-weight: 600;
    }

    .modal-large-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 24px;
        margin-top: 5px;
        color: #ffffff;
    }

    .modal-larger-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 30px;
        margin-top: 5px;
        color: #ffffff;
    }

    .modal-btn {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 4px;
        margin-top: 25px;
    }
    .modal-input {
        width: 100%;
        background: rgba(11, 0, 30, 0.4);
        border: 1.2px solid #efebff;
        border-radius: 10px;
        // outline: none;

        .MuiOutlinedInput-input {
            font-family: Proxima;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
            padding: 10px 14px;
            text-align: right;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }

        .modal-input-btn {
            cursor: pointer;

            p {
                font-family: Proxima;
                font-weight: 600;
                font-size: 16px;
                color: #777;
            }
        }
    }

    .radio-input {
        color: #ffffff;
        .MuiRadio-root {
            color: #ffffff;
        }

        .MuiTypography-root {
            font-family: Proxima;
        }
    }
}

.info-card-text {
    font-family: Montserrat;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 30px;
    padding: 0.5rem 1.25rem;
}
.announcement-card {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;

    .announcement-card-text {
        font-family: Montserrat;
        color: #ffffff;
    }
}

@media screen and (max-width: 600px) {
    .stake-view {
        .stake-card {
            .stake-card-metrics {
                .stake-card-apy,
                .stake-card-tvl,
                .stake-card-index {
                    align-items: flex-start;
                }

                .stake-card {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .stake-card-area {
                .stake-card-action-area {
                    .stake-card-action-stage-btns-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .stake-card-action-stage-btn {
                            margin: 0px 8px;
                            padding: 6px;
                            cursor: pointer;
                            transition: all 0.4s ease;

                            p {
                                font-family: Montserrat SemiBold;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: rgba(255, 255, 255, 0.6);
                            }
                        }

                        .stake-card-action-stage-btn.active {
                            p {
                                color: #ffffff;
                            }

                            border-bottom: 1px solid #ffffff;
                        }
                    }
                    .stake-card-action-row {
                        flex-direction: column;
                        .stake-card-tab-panel {
                            margin-left: 0;
                            margin-top: 20px;
                            min-width: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .stake-view {
        width: 100%;
    }
}
