.stake-view {
    width: 89%;
    max-width: 600px;
    margin: auto;

    .stake-card {
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(36, 39, 41, 0.1);
            backdrop-filter: blur(40px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        border-radius: 10px;
        padding: 20px;
        width: 100%;

        .stake-card-grid {
            margin: 0;
            width: 100%;
        }

        .stake-card-header {
            .stake-card-header-title {
                font-family: Proxima;
                font-weight: bold;
                font-size: 20px;
                color: #ffffff;
                margin-bottom: 4px;
            }
        }

        .stake-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;

            .stake-card-apy,
            .stake-card-tvl,
            .stake-card-index {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .stake-card-metrics-title {
                    font-family: Montserrat Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 2px;
                }

                .stake-card-metrics-value {
                    font-family: Montserrat Bold;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    color: #ffffff;
                }
            }
        }

        .stake-card-area {
            .stake-card-wallet-notification {
                text-align: center;

                .stake-card-wallet-connect-btn {
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                    border-radius: 10px;
                    padding: 14px 58px;
                    cursor: pointer;
                    margin: auto;
                    max-width: 280px;
                    margin-top: 20px;

                    p {
                        font-family: Montserrat SemiBold;
                        font-weight: 600;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                .stake-card-wallet-desc-text {
                    font-family: Montserrat Light;
                    font-weight: 300;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
            }

            .stake-card-action-area {
                width: 100%;
                margin-top: 25px;

                .stake-card-action-stage-btns-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .stake-card-action-stage-btn {
                        margin: 0px 30px;
                        padding: 6px;
                        cursor: pointer;
                        transition: all 0.4s ease;

                        p {
                            font-family: Montserrat SemiBold;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }

                    .stake-card-action-stage-btn.active {
                        p {
                            color: #ffffff;
                        }

                        border-bottom: 1px solid #ffffff;
                    }
                }

                .stake-card-action-row {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    .stake-card-action-input {
                        width: 100%;

                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        border-radius: 10px;
                        outline: none;

                        .MuiOutlinedInput-input {
                            font-family: Montserrat Medium;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.6);
                            padding: 14px 20px;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: rgba(255, 255, 255, 0.2) !important;
                        }

                        .stake-card-action-input-btn {
                            cursor: pointer;

                            p {
                                font-family: Montserrat SemiBold;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }

                    .stake-card-tab-panel {
                        min-width: 215px;
                        margin-left: 10px;

                        .stake-card-tab-panel-btn {
                            background: rgba(255, 255, 255, 0.2);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            border-radius: 10px;
                            padding: 14px 0px;
                            cursor: pointer;
                            text-align: center;
                            width: 100%;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }
                    .stake-card-tab-panel-full {
                        width: 100%;
                        justify-content: center;
                        display: flex;

                        .stake-card-tab-panel-btn {
                            background: rgba(255, 255, 255, 0.2);
                            border: 1px solid transparent;
                            border-radius: 32px;
                            padding: 14px 0px;
                            cursor: pointer;
                            text-align: center;
                            width: 100%;

                            &:hover {
                                background: rgba(255, 255, 255, 0.4);
                            }
                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                        .stake-card-tab-panel-btn.primary {
                            background: linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(177,157,255,1) 100%);
                            border-color: #b19dff;

                            &:hover {
                                background: linear-gradient(90deg, rgba(56,84,225,1) 0%, rgba(159,141,229,1) 100%);
                            }
                        }
                        .stake-card-tab-panel-btn.secondary {
                            background: #ffffff;
                            border-color: #3f5efb;

                            &:hover {
                                background: #ebeefe;
                            }

                            p {
                                color: #3f5efb;                                ;
                            }
                        }
                    }
                }

                .stake-card-action-help-text {
                    margin-top: 10px;

                    p {
                        font-family: Montserrat Light;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }

            .stake-user-data {
                justify-content: center;
                margin: auto;
                padding: 0 5px;
                margin-top: 30px;

                .data-row {
                    display: flex;
                    justify-content: space-between;
                    margin: 1.5rem 0;
                
                    .data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        color: #ffffff;
                    }
                    .data-row-value {
                        font-family: Proxima;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 17px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.data-row {
    display: flex;
    justify-content: space-between;
    margin: 12px 0px;

    .data-row-name {
        font-family: Montserrat Medium;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
    }
    .data-row-value {
        font-family: Montserrat;
        font-size: 14px;
        line-height: 17px;
        color: #ffffff;
    }
}

.ml-2 {
    margin-left: 1rem;
}
.mt-3 {
    margin-top: 2rem;
}
.mb-3 {
    margin-bottom: 2rem;
}

.text-light {
    color: #ffffff;
}
.text-secondary {
    color: #777;
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: 700;
}
.modal-box {
    position: absolute;
    box-shadow: 0px 0px 10px #777;
    top: 50%;
    left: 50%;
    width: 600px;
    background: #ffffff;
    backdrop-filter: blur(40px);
    padding: 2rem;
    text-align: center;
    border-radius: 8px;
    transform: translate(-50%, -50%);

    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;

        .modal-title {
            font-family: Proxima;
            font-weight: 700;
            font-size: 30px;
            color: #333;
        }
        .modal-close {
            background-color: #ffffff;
            cursor: pointer;
            font-weight: 700;
        }
    }
    .modal-text {
        font-family: Proxima;
        font-size: 18px;
        margin-top: 10px;
        color: #333;
    }    
    .modal-normal-text {
        font-family: Proxima;
        font-size: 16px;
        color: #333;
        margin-bottom: 20px;
    }
    
    .modal-large-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 24px;
        margin-top: 5px;
        color: #333;
    }
    
    .modal-larger-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 30px;
        margin-top: 5px;
        color: #333;
    }

    .modal-data-row {
        display: flex;
        justify-content: space-between;
        margin: 12px 0px;
    
        .modal-data-row-name {
            font-family: Proxima;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #181b1c;
        }
        .modal-data-row-value {
            font-family: Proxima;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            color: #181b1c;
        }
    }
    
    .modal-btn {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 4px;
        margin-top: 25px;
    }
    .modal-input {
        width: 100%;
        background: #f7f5ff;
        border: 3px solid #efebff;
        border-radius: 10px;
        outline: none;
    
        .MuiOutlinedInput-input {
            font-family: Proxima;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #333;
            padding: 14px 20px;
            text-align: right;
        }
    
        .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }
    
        .modal-input-btn {
            cursor: pointer;
    
            p {
                font-family: Proxima;
                font-weight: 600;
                font-size: 16px;
                color: #777;
            }
        }
    }
    
    .modal-panel-btn {
        background: #3f5efb;
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 32px;
        padding: 14px 0px;
        cursor: pointer;
        text-align: center;
        margin-top: 1.5rem;

        &:hover {
            background: #3854e1;
        }
    
        p {
            font-family: Proxima;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 600px) {
    .stake-view {
        .stake-card {
            .stake-card-metrics {
                .stake-card-apy,
                .stake-card-tvl,
                .stake-card-index {
                    align-items: flex-start;
                }

                .stake-card {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .stake-card-area {
                .stake-card-action-area {
                    .stake-card-action-stage-btns-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .stake-card-action-stage-btn {
                            margin: 0px 8px;
                            padding: 6px;
                            cursor: pointer;
                            transition: all 0.4s ease;

                            p {
                                font-family: Montserrat SemiBold;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: rgba(255, 255, 255, 0.6);
                            }
                        }

                        .stake-card-action-stage-btn.active {
                            p {
                                color: #ffffff;
                            }

                            border-bottom: 1px solid #ffffff;
                        }
                    }
                    .stake-card-action-row {
                        flex-direction: column;

                        .stake-card-tab-panel {
                            margin-top: 20px;
                            min-width: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .stake-view {
        width: 100%;
    }
}
