.pool-view {
    width: 89%;
    max-width: 900px;
    margin: auto;

    .pool-card {
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(217, 217, 217, 0.05);
            backdrop-filter: blur(20px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        border-radius: 20px;
        padding: 20px;
        width: 100%;

        .pool-card-grid {
            margin: 0;
            width: 100%;

            .pool-card-item-btn {
                text-transform: none;
                font-family: Proxima;
                font-weight: 600;
                color: rgba(255, 255, 255, 0.8);
                background: rgba(159, 141, 229, 0.1);
                border: 4.89302px solid rgba(255, 255, 255, 0.05);
                box-shadow: 0px 3.26202px 16.3101px rgba(0, 0, 0, 0.1);
                margin-top: 1rem;
                border-radius: 10px;

                &:hover {
                    background: rgba(159, 141, 229, 0.3);
                    // border: 4.89302px solid rgba(255, 255, 255, 0.05);
                }

                img {
                    margin-left: 0.75rem;
                }
            }
            .pool-card-item-btn.approve-action {
                text-transform: none;
                font-family: Proxima;
                font-weight: 600;
            }
            .pool-card-item-btn.collapse-btn {
                background: linear-gradient(180deg, rgba(159, 141, 229, 0.3) 0%, rgba(30, 49, 140, 0.3) 68.75%), rgba(217, 217, 217, 0.3);
                border: 0.601701px solid rgba(255, 255, 255, 0.6);
                box-shadow: 0px 2.40681px 27.6783px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(12.034px);
            }
        }

        .pool-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;

            .pool-card-apy,
            .pool-card-tvl,
            .pool-card-index {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .pool-card-metrics-title {
                    font-family: Proxima;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 2px;
                }

                .pool-card-metrics-value {
                    font-family: Proxima;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    color: #ffffff;
                }
            }
        }

        .pool-card-area {
            padding: 8px;

            .pool-card-header {
                display: flex;
                justify-content: space-between;

                .pool-card-header-title {
                    font-family: Proxima;
                    font-weight: 700;
                    font-size: 30px;
                    color: #ffffff;
                    margin-bottom: 4px;
                }

                .setting-icon:hover {
                    cursor: pointer;
                }
            }
            .pool-card-wallet-notification {
                text-align: center;

                .pool-card-wallet-connect-btn {
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                    border-radius: 10px;
                    padding: 14px 58px;
                    cursor: pointer;
                    margin: auto;
                    max-width: 280px;
                    margin-top: 20px;

                    p {
                        font-family: Proxima;
                        font-weight: 600;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                .pool-card-wallet-desc-text {
                    font-family: Proxima;
                    font-weight: 300;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
            }

            .pool-card-action-area {
                width: 100%;
                margin-top: 25px;

                .pool-card-action-stage-btns-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 0.5rem;

                    .pool-card-action-stage-btn {
                        margin: 0px 10px;
                        padding: 6px;
                        cursor: pointer;
                        // transition: all 0.4s ease;

                        p {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 14px;
                            letter-spacing: 0.15em;
                            color: rgba(255, 255, 255, 0.3);
                        }
                    }

                    .pool-card-action-stage-btn.active {
                        p {
                            font-weight: 600;
                            color: rgba(255, 255, 255, 0.8);
                        }

                        border-bottom: 2px solid rgba(255, 255, 255, 0.8);
                    }
                }

                .pool-card-action-row {
                    display: flex;
                    margin-top: 20px;

                    &.staking-data {
                        flex-direction: column;

                        .staking-data-row {
                            display: flex;
                            justify-content: space-between;
                            margin: 12px 0px;
                            align-items: center;
                            padding: 0 8px;

                            .staking-data-row-name {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 12px;
                                letter-spacing: 0.15em;
                                color: #ffffff;
                            }
                            .staking-data-row-value {
                                font-family: Proxima;
                                font-size: 16px;
                                line-height: 23px;
                                color: #ffffff;
                            }
                        }
                    }

                    .pool-card-action-input {
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                        background: rgba(11, 0, 30, 0.2);
                        border: 1px solid transparent;
                        border-radius: 12px;
                        outline: none;

                        .MuiOutlinedInput-input {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            color: #ffffff;
                            padding: 12px 18px;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: rgba(255, 255, 255, 0.2) !important;
                        }

                        .pool-card-action-input-btn {
                            cursor: pointer;
                            background: rgba(11, 0, 30, 0.4);
                            padding: 6px 10px;
                            border-radius: 8px;

                            p {
                                font-family: Proxima;
                                font-weight: 600;
                                font-size: 14px;
                                color: #ffffff;
                            }
                        }
                    }
                    .pool-card-action-input.MuiInputBase-root {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        color: #ffffff;
                        padding: 14px 20px;
                    }

                    .pool-card-tab-panel {
                        min-width: 215px;
                        margin-left: 10px;

                        .pool-card-tab-panel-btn {
                            background: rgba(255, 255, 255, 0.2);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            border-radius: 10px;
                            padding: 14px 0px;
                            cursor: pointer;
                            text-align: center;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }

                    .pool-card-tab-panel-3 {
                        width: 34%;
                        margin-left: 10px;

                        .pool-card-tab-panel-btn {
                            background: rgba(255, 255, 255, 0.2);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            border-radius: 10px;
                            padding: 14px 0px;
                            cursor: pointer;
                            text-align: center;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }
                }

                .pool-card-action-help-text {
                    margin-top: 10px;

                    p {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }

            .pool-user-data {
                justify-content: center;
                margin: auto;
                padding: 0 5px;
                margin-top: 20px;
                margin-bottom: 10px;
                text-align: center;

                .user-data-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    background: rgba(159, 141, 229, 0.1);
                    border-radius: 12px;
                    padding: 0.5rem;
                    height: 100%;
                    justify-items: center;

                    .user-data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        margin-top: 6px;
                        font-weight: 500;
                        font-size: 12px;
                        color: #ffffffa9;
                        line-height: 14px;
                        letter-spacing: 0.05em;
                    }
                    .user-data-row-value {
                        font-family: Proxima;
                        margin: 6px 0px;
                        font-size: 24px;
                        color: #ffffff;
                    }
                    .user-data-row-value-2 {
                        font-family: Proxima;
                        font-size: 18px;
                        line-height: 25px;
                        color: #cccccc;
                    }
                    .user-data-row-btn {
                        text-transform: none;
                        font-family: Proxima;
                        font-weight: 600;
                        color: rgba(255, 255, 255, 0.8);
                        background: rgba(159, 141, 229, 0.1);
                        border: 0.1px solid rgba(255, 255, 255, 0.05);
                        box-shadow: 0px 3.26202px 16.3101px rgba(0, 0, 0, 0.1);
                        border-radius: 10px;

                        &:hover {
                            background: rgba(159, 141, 229, 0.3);
                        }
                    }
                }
            }

            .underline {
                background-color: rgba(255, 255, 255, 0.4);
            }

            .pool-card-item {
                background: rgba(217, 217, 217, 0.05);
                backdrop-filter: blur(16.3101px);
                border: 1px solid transparent;
                border-radius: 10px;
                padding: 0.75rem;
                color: #ffffff;

                .pool-card-item-header {
                    display: flex;
                    align-items: center;
                    margin-bottom: 4px;

                    .pool-card-item-header-title {
                        font-family: Proxima;
                        font-weight: bold;
                        font-size: 1.5rem;
                        margin-left: 8px;
                    }
                    .pool-card-item-header-label {
                        margin-left: auto;
                    }
                    .pool-card-item-header-label.MuiChip-root {
                        background: linear-gradient(90deg, rgba(63, 94, 251, 1) 0%, rgba(177, 157, 255, 1) 100%);
                        color: #ffffff;
                        font-family: Proxima;
                        font-weight: 600;
                    }
                }

                .pool-apr-data {
                    justify-content: center;
                    margin: auto;
                    padding: 0 5px;
                    margin-top: 30px;
                    color: #ffffff;
                    margin-bottom: 30px;

                    .pool-card-action-input {
                        width: 100%;
                        text-align: center;
                        cursor: pointer;
                        background: #f7f5ff;
                        border: 2px solid #e7e1ff;
                        border-radius: 10px;
                        outline: none;

                        .MuiOutlinedInput-input {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            color: #333;
                            padding: 14px 20px;
                            text-align: right;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: transparent !important;
                        }

                        .pool-card-action-input-btn {
                            cursor: pointer;
                            font-family: Proxima;
                            font-weight: 600;
                            font-size: 14px;
                            text-transform: none;
                            color: #777;
                        }
                    }
                }
                .data-row {
                    display: flex;
                    justify-content: space-between;
                    margin: 12px 0px;
                    align-items: center;

                    .data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        letter-spacing: 0.15em;
                        color: #ffffff;
                    }
                    .data-row-value {
                        font-family: Proxima;
                        font-size: 18px;
                        line-height: 23px;
                        color: #ffffff;
                    }
                }

                .data-row-apr {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    margin: auto;
                    margin-bottom: 10px;

                    .data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        letter-spacing: 0.15em;
                        color: rgba(255, 255, 255, 0.3);
                        margin-bottom: 15px;
                    }
                    .data-row-value {
                        font-family: Proxima;
                        font-size: 30px;
                        line-height: 17px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

.mt-0 {
    margin-top: 0 !important;
}
.mt-1 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 2rem;
}
.mb-3 {
    margin-bottom: 2rem;
}

.text-light {
    color: #ffffff;
}
.text-secondary {
    color: #777;
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: 700;
}
.pool-modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 600px;
    background: rgba(217, 217, 217, 0.1);
    backdrop-filter: blur(40px);
    padding: 2rem;
    text-align: start;
    border: 1px solid #777;
    border-radius: 12px;
    transform: translate(-50%, -50%);

    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .modal-title {
            font-family: Proxima;
            font-weight: 700;
            font-size: 24px;
            color: #ffffff;
        }
        .modal-close {
            color: #ffffff;
            cursor: pointer;
            font-weight: 700;
        }
    }

    .modal-text {
        font-family: Proxima;
        font-size: 18px;
        margin-top: 10px;
        color: #333;
    }

    .modal-normal-text {
        font-family: Proxima;
        font-size: 16px;
        color: #f5f5f5;
        margin-top: 20px;
        text-align: start;
        font-weight: 600;
    }

    .modal-large-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 24px;
        margin-top: 5px;
        color: #ffffff;
    }

    .modal-larger-text {
        font-family: Proxima;
        font-weight: 600;
        font-size: 30px;
        margin-top: 5px;
        color: #ffffff;
    }

    .modal-data-row {
        display: flex;
        justify-content: space-between;
        margin: 14px 0px;

        .modal-data-row-name {
            font-family: Proxima;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #ffffff;

            .tooltip-icon {
                margin-left: 4px;
            }
        }
        .modal-data-row-value {
            font-family: Proxima;
            font-size: 16x;
            line-height: 19px;
            color: #ffffff;
        }
    }

    .modal-btn {
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
        border-radius: 4px;
        margin-top: 25px;
    }

    .modal-input {
        width: 100%;
        background: rgba(11, 0, 30, 0.4);
        border: 1.2px solid #efebff;
        border-radius: 12px;
        // outline: none;

        .MuiOutlinedInput-input {
            font-family: Proxima;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
            padding: 12px 18px;
            text-align: right;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: rgba(255, 255, 255, 0.2) !important;
        }

        .modal-input-btn {
            cursor: pointer;
            background: rgba(255, 255, 255, 0.05);
            padding: 6px 10px;
            border-radius: 8px;

            p {
                font-family: Proxima;
                font-weight: 600;
                font-size: 12px;
                color: #ffffff;
            }
        }
    }

    .radio-input {
        color: #ffffff;

        .MuiRadio-root {
            color: #ffffff;
        }

        .MuiTypography-root {
            font-family: Proxima;
        }
    }

    .modal-panel-btn {
        background: linear-gradient(180deg, rgba(159, 141, 229, 0.3) 0%, rgba(30, 49, 140, 0.3) 68.75%), rgba(217, 217, 217, 0.4);
        border: 2px solid rgba(255, 255, 255, 0.6);
        box-shadow: 0px 2.40681px 27.6783px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(12.034px);
        /* Note: backdrop-filter has minimal browser support */

        border-radius: 9.78605px;
        border-radius: 12px;
        padding: 14px 0px;
        cursor: pointer;
        text-align: center;
        margin-top: 1.5rem;

        &:hover {
            background: linear-gradient(180deg, rgba(159, 141, 229, 0.5) 0%, rgba(30, 49, 140, 0.5) 68.75%), rgba(217, 217, 217, 0.5);
        }

        p {
            font-family: Proxima;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #ffffff;
        }
    }
}

.header-content-label {
    font-size: 12px;
    color: #222;
}

.header-content-title {
    font-size: 16px;
    color: #ddd;
}

.info-card-text {
    font-family: Montserrat;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 30px;
    padding: 0.5rem 1.25rem;
}
.announcement-card {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;

    .announcement-card-text {
        font-family: Montserrat;
        color: #ffffff;
    }
}

@media screen and (max-width: 600px) {
    .pool-view {
        .pool-card {
            .pool-card-metrics {
                .pool-card-apy,
                .pool-card-tvl,
                .pool-card-index {
                    align-items: flex-start;
                }

                .pool-card {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .pool-card-area {
                .pool-card-action-area {
                    .pool-card-action-stage-btns-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #777;
                        margin-top: 10px;

                        .pool-card-action-stage-btn {
                            margin: 0px 8px;
                            padding: 6px;
                            cursor: pointer;
                            transition: all 0.4s ease;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 12px;
                                line-height: 14px;
                                letter-spacing: 0.15em;
                                color: rgba(255, 255, 255, 0.3);
                            }
                        }

                        .pool-card-action-stage-btn.active {
                            p {
                                color: #ffffff;
                            }

                            border-bottom: 1px solid #ffffff;
                        }
                    }
                    .pool-card-action-row {
                        flex-direction: column;
                        .pool-card-tab-panel {
                            margin-left: 0;
                            margin-top: 20px;
                            min-width: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .pool-view {
        width: 100%;
    }
}
