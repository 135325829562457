@font-face {
    font-family: "Montserrat SemiBold";
    src: local("Montserrat SemiBold"), url("../fonts/Exo2-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: local("Montserrat"), url("../fonts/Exo2-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Bold";
    src: local("Montserrat Bold"), url("../fonts/Exo2-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Medium";
    src: local("Montserrat Medium"), url("../fonts/Exo2-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat Light";
    src: local("Montserrat Light"), url("../fonts/Exo2-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Courier";
    src: local("Courier"), url("../fonts/CourierPrime-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Proxima";
    src: local("Proxima"), url("../fonts/Proxima-Nova-Font.otf") format("opentype");
}
