.dapp-navbar {
  width: 89%;
  max-width: 833px;
  align-items: center;
  margin: auto;

  .dapp-navbar-header {
    display: inline-flex;
    align-items: center;

    .header-title {
      margin-left: 0.5rem;
    }
  }
  .dapp-navbar-slider-btn{
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 16px;
  }

  .dapp-navbar-btns-wrap{
    display: flex;
    align-items: center;
    margin-left: auto;
  }
}

@media screen and (min-width: 960px) {
  .dapp-navbar{
    .dapp-navbar-slider-btn{
      display: none;
    }
  }
}