.faucet-view {
    width: 89%;
    max-width: 600px;
    margin: auto;

    .faucet-card {
        position: relative;
        z-index: 2;

        @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
            background: rgba(217, 217, 217, 0.05);
            backdrop-filter: blur(20px);
        }

        /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
        @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
            background: #181b1c;
        }

        border-radius: 20px;
        padding: 20px;
        width: 100%;

        .faucet-card-grid {
            margin: 0;
            width: 100%;
        }

        .faucet-card-header {
            display: flex;
            justify-content: space-between;

            .faucet-card-header-title {
                font-family: Proxima;
                font-weight: 700;
                font-size: 30px;
                color: #ffffff;
                margin-bottom: 4px;
            }
        }

        .faucet-card-metrics {
            display: flex;
            width: 100%;
            padding: 0;
            text-align: center;
            margin-top: 10px;

            .faucet-card-apy,
            .faucet-card-tvl,
            .faucet-card-index {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                .faucet-card-metrics-title {
                    font-family: Proxima;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-bottom: 2px;
                }

                .faucet-card-metrics-value {
                    font-family: Proxima;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 29px;
                    color: #ffffff;
                }
            }
        }

        .faucet-card-area {
            .faucet-card-wallet-notification {
                text-align: center;

                .faucet-card-wallet-connect-btn {
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                    border-radius: 10px;
                    padding: 14px 58px;
                    cursor: pointer;
                    margin: auto;
                    max-width: 280px;
                    margin-top: 20px;

                    p {
                        font-family: Proxima;
                        font-weight: 600;
                        font-size: 18px;
                        color: #ffffff;
                    }
                }

                .faucet-card-wallet-desc-text {
                    font-family: Proxima;
                    font-weight: 300;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.6);
                    margin-top: 10px;
                    margin-bottom: 6px;
                }
            }

            .faucet-card-action-area {
                width: 100%;
                justify-content: center;
                margin: auto;
                margin-top: 25px;
                padding: 0 5px;

                p {
                    font-family: Proxima;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: rgba(255, 255, 255, 0.6);
                    line-height: 20px;
                    text-overflow: clip;

                    &.help-text {
                        margin-top: 10px;
                        font-size: 12px;
                        font-family: Proxima;
                        font-weight: 500;
                        line-height: 16px;
                    }
                }

                .faucet-card-action-stage-btns-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .faucet-card-action-stage-btn {
                        margin: 0px 30px;
                        padding: 6px;
                        cursor: pointer;
                        transition: all 0.4s ease;

                        p {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            color: rgba(255, 255, 255, 0.6);
                        }
                    }

                    .swap-card-action-stage-btn.active {
                        p {
                            color: #ffffff;
                        }

                        border-bottom: 1px solid #ffffff;
                    }
                }
                .info-data-row {
                    display: flex;
                    flex-direction: column;
                    background: rgba(159, 141, 229, 0.1);
                    border-radius: 12px;
                    padding: 1rem;

                    .info-data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        margin-top: 6px;
                        font-weight: 500;
                        font-size: 10px;
                        color: #ffffffa9;
                        line-height: 14px;
                        letter-spacing: 0.05em;
                    }
                    .info-data-row-value {
                        font-family: Proxima;
                        margin: 6px 0;
                        font-size: 16px;
                        line-height: 23px;
                        color: #ffffff;
                    }
                }

                .faucet-card-action-row {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;

                    .faucet-card-action-input {
                        width: 100%;
                        height: 108px;
                        background: rgba(11, 0, 30, 0.2);
                        border-radius: 12px;
                        outline: none;
                        position: relative;

                        .MuiOutlinedInput-input {
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 30px;
                            color: #ffffff;
                            padding: 14px 20px;
                            text-align: right;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            border-color: rgba(255, 255, 255, 0.2) !important;
                        }

                        .faucet-card-action-input-btn {
                            cursor: pointer;
                            border: 1px solid rgba(255, 255, 255, 0.6);
                            border-radius: 4px;
                            padding: 0.1rem 0.5rem;
                            background-color: rgba(255, 255, 255, 0.2);

                            p {
                                font-family: Proxima;
                                font-weight: 600;
                                font-size: 14px;
                                color: #ffffff;
                            }
                        }
                    }

                    .faucet-card-select-input {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid rgba(255, 255, 255, 0.2);
                        border-radius: 16px;
                        outline: none;
                        width: auto;
                        // height: 100%;
                        position: absolute;
                        right: 25px;
                        margin: 12px 0;

                        .MuiSelect-select {
                            display: inline-flex;
                            align-items: center;
                            font-family: Proxima;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.6);
                            padding: 10px 20px;
                        }

                        .token-info {
                            display: flex;
                            flex-direction: column;
                            justify-items: center;
                            margin-left: 0.5rem;
                            margin-right: 0.5rem;

                            .token-title {
                                font-size: 1.25rem;
                                font-weight: 600;
                                color: #ffffff;
                            }

                            .token-balance {
                                font-size: 0.65rem;
                                font-weight: 300;
                                color: #ffffff86;
                            }
                        }

                        .faucet-card-action-input-btn {
                            cursor: pointer;

                            p {
                                font-family: Proxima;
                                font-weight: 600;
                                font-size: 18px;
                                color: #ffffff;
                            }
                        }
                    }

                    .faucet-card-tab-panel {
                        width: 100%;

                        .faucet-card-tab-panel-btn {
                            // background: rgba(255, 255, 255, 0.2);
                            background: linear-gradient(180deg, rgba(159, 141, 229, 0.3) 0%, rgba(30, 49, 140, 0.3) 68.75%), rgba(217, 217, 217, 0.4);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            backdrop-filter: blur(14.7566px);
                            border: 0.737828px solid rgba(255, 255, 255, 0.6);
                            border-radius: 12px;
                            padding: 12px 0px;
                            cursor: pointer;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:hover {
                                background: linear-gradient(180deg, rgba(159, 141, 229, 0.3) 0%, rgba(30, 49, 140, 0.3) 68.75%), rgba(217, 217, 217, 0.6);
                            }
                            &.disabled {
                                background: rgba(217, 217, 217, 0.4);
                                border-color: transparent;
                            }

                            .btn-icon {
                                margin-right: 6px;
                            }

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 16px;
                                color: #ffffff;
                            }
                        }
                        .faucet-card-tab-panel-text-left {
                            font-family: Proxima;
                            font-style: bold;
                            font-size: 16px;
                            color: #ffffff;
                        }
                    }
                    .faucet-card-tab-panel-center {
                        width: 100%;
                        text-align: center;

                        .faucet-img {
                            border: 1px solid #ffffff;
                            padding: 0.5rem;
                            border-radius: 50%;
                        }

                        .faucet-img:hover {
                            cursor: pointer;
                            background-color: #b19dff;
                        }
                    }
                    .faucet-card-tab-panel-text-right {
                        font-family: Proxima;
                        font-style: normal;
                        font-size: 14px;
                        color: #ffffff;
                        text-align: right;
                        width: 100%;
                    }
                }

                .faucet-card-action-help-text {
                    margin-top: 10px;

                    p {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 17px;
                        text-align: center;
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }

            .faucet-token-data {
                justify-content: center;
                margin: auto;
                padding: 0 5px;
                margin-top: 30px;
                text-align: center;

                .data-row {
                    display: flex;
                    justify-content: space-between;
                    background: rgba(159, 141, 229, 0.1);
                    border-radius: 12px;
                    padding: 0.5rem 0.75rem;
                    align-items: center;

                    .data-row-name {
                        font-family: Proxima;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 14px;
                        display: flex;
                        align-items: center;

                        .data-row-name-title {
                            margin-left: 8px;
                        }
                    }
                    .data-row-value {
                        font-family: Proxima;
                        margin: 6px 0px;
                        font-size: 14px;
                        line-height: 29px;
                        color: #ffffff;
                    }
                    .data-row-link {
                        font-size: 12px;
                        color: #ffffff;
                        font-family: Proxima;
                        font-weight: 500;

                        .data-row-link-btn {
                            background: rgba(159, 141, 229, 0.1);
                            box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
                            backdrop-filter: blur(14.7566px);
                            border: 3px solid rgba(255, 255, 255, 0.05);
                            border-radius: 16px;
                            padding: 10px;
                            cursor: pointer;
                            text-align: center;

                            p {
                                font-size: 10px;
                                color: #ffffff;
                                font-family: Proxima;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}

.MuiMenuItem-root {
    display: inline-flex;
    align-items: center;
    font-family: Proxima !important;

    .token-info {
        display: flex;
        flex-direction: column;
        justify-items: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;

        .token-title {
            font-size: 0.875rem;
            font-weight: 600;
            font-style: normal;
        }

        .token-balance {
            font-size: 8px;
            font-weight: 300;
            font-style: light;
            color: #777;
        }
    }
}

.mt-0 {
    margin-top: 0;
}
.mt-3 {
    margin-top: 2rem;
}
.mb-3 {
    margin-bottom: 2rem;
}

.text-light {
    color: #ffffff;
}
.text-secondary {
    color: #777;
}

.text-center {
    text-align: center;
}
.text-bold {
    font-weight: 700;
}

.info-card-text {
    font-family: Proxima;
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 30px;
    padding: 0.5rem 1.25rem;
}
.announcement-card {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;

    .announcement-card-text {
        font-family: Proxima;
        color: #ffffff;
    }
}

@media screen and (max-width: 600px) {
    .faucet-view {
        .faucet-card {
            .faucet-card-area {
                .faucet-card-action-area {
                    .info-data-row {
                        .info-data-row-name {
                            font-size: 6px;
                            line-height: 8px;
                            letter-spacing: 0.05em;
                        }
                        .info-data-row-value {
                            margin: 4px 0;
                            font-size: 10px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }
    .stake-view {
        .stake-card {
            .stake-card-metrics {
                .stake-card-apy,
                .stake-card-tvl,
                .stake-card-index {
                    align-items: flex-start;
                }

                .stake-card {
                    width: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }

            .stake-card-area {
                .stake-card-action-area {
                    .stake-card-action-stage-btns-wrap {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .stake-card-action-stage-btn {
                            margin: 0px 8px;
                            padding: 6px;
                            cursor: pointer;
                            transition: all 0.4s ease;

                            p {
                                font-family: Proxima;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 18px;
                                color: rgba(255, 255, 255, 0.6);
                            }
                        }

                        .stake-card-action-stage-btn.active {
                            p {
                                color: #ffffff;
                            }

                            border-bottom: 1px solid #ffffff;
                        }
                    }
                    .stake-card-action-row {
                        flex-direction: column;
                        .stake-card-tab-panel {
                            margin-left: 0;
                            margin-top: 20px;
                            min-width: none;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1240px) {
    .stake-view {
        width: 100%;
    }
}
